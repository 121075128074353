// material design
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning as iconWarning } from "@fortawesome/pro-duotone-svg-icons";
import { faRefresh as iconRefresh } from "@fortawesome/pro-duotone-svg-icons";
import { faCreditCard as iconPay } from "@fortawesome/pro-duotone-svg-icons";
import { faHorizontalRule as iconPaySlot } from "@fortawesome/pro-regular-svg-icons";
import { faChevronsDown as iconPullDown } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSpinnerThird as iconSpinner } from "@fortawesome/pro-duotone-svg-icons";
import { faEnvelope as iconDM } from "@fortawesome/pro-duotone-svg-icons";
import { faMoneyBillTrendUp as iconPL } from "@fortawesome/pro-duotone-svg-icons";
import { faBan as iconDisabled } from "@fortawesome/pro-solid-svg-icons";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";

// content from https://flagpedia.net/
const flags = require.context("assets/flags", true);

export function ActiveTag(props) {
  let text = props.value ? "ACTIVE" : "INACTIVE";
  if (props.prefix) text = props.prefix + " " + text;
  if (props.suffix) text += " " + props.suffix;
  return (
    <Chip
      label={text}
      className={
        "shadow-default upper-case " + (props.onClick ? "action-pointer" : "")
      }
      onDelete={props.onDelete}
      onClick={props.onClick}
      sx={{
        color: "black",
        backgroundColor: props.value ? "lightgreen" : "lightsalmon",
        p:
          props.size === "md"
            ? configEntzy.APP_SPACING_SM
            : configEntzy.APP_SPACING_SM,
        fontWeight: "500",
        fontSize:
          props.size === "md"
            ? configEntzy.FONT_SCALE_MD
            : configEntzy.FONT_SCALE_SM,
      }}
    />
  );
}

export function TextTag(props) {
  return (
    <Box
      className={
        (props.fullWidth ? "box-default " : "box-inline ") +
        props.float +
        " bg-white-t25" +
        (props.shadow ? " shadow-default" : "") +
        (props.shadowLight ? " shadow-light" : "") +
        (props.line ? " shadow-subtle" : "") +
        (props.onClick ? " action-pointer" : "")
      }
      onClick={props.onClick}
      sx={{
        p:
          props.size === "xl"
            ? configEntzy.APP_SPACING_SM
            : props.size === "lg"
            ? configEntzy.APP_SPACING_SM
            : props.size === "md"
            ? configEntzy.APP_SPACING_XS
            : configEntzy.APP_SPACING_XS,
        m: props.margin
          ? configEntzy.APP_SPACING_SM
          : configEntzy.APP_SPACING_XS,
        backgroundColor: props.bgColor,
        borderRadius: props.rounded ? configEntzy.BORDER_SIZE_XL : 0,
        overflow: "hidden",
      }}
    >
      <Typography
        variant={
          props.size === "xl"
            ? "h3"
            : props.size === "lg"
            ? "h6"
            : props.size === "md"
            ? "subtitle2"
            : "body2"
        }
        sx={{
          overflow: "visible",
        }}
        className={
          props.textNoCasing
            ? "inherit"
            : props.lower
            ? "lower-case"
            : "upper-case"
        }
        color={props.color}
        noWrap={true}
      >
        {props.flag ? (
          <span>
            &nbsp;
            <img
              src={flags("./" + props.flag + ".png")}
              alt=""
              width={
                props.size === "xl"
                  ? "25rem"
                  : props.size === "lg"
                  ? "20rem"
                  : props.size === "md"
                  ? "16rem"
                  : "12rem"
              }
              height={
                props.size === "xl"
                  ? "25rem"
                  : props.size === "lg"
                  ? "20rem"
                  : props.size === "md"
                  ? "16rem"
                  : "12rem"
              }
              style={{
                margin: "auto 0",
                display: "inline-block",
                verticalAlign: "middle",
                paddingBottom:
                  props.size === "xl"
                    ? "0.2rem"
                    : props.size === "lg"
                    ? "0.2rem"
                    : props.size === "md"
                    ? "0.1rem"
                    : "0.1rem",
              }}
            />
            &nbsp;
          </span>
        ) : (
          <span>&nbsp;{props.text}&nbsp;</span>
        )}
      </Typography>
    </Box>
  );
}

export function HeaderTag(props) {
  return (
    <Box
      className="box-default"
      sx={{
        pb: configEntzy.APP_SPACING_MD2X,
      }}
    >
      <Box className="box-default">
        <Chip
          label={props.text}
          sx={{
            p: configEntzy.APP_SPACING_SM,
            fontWeight: "500",
            fontSize: configEntzy.FONT_SCALE_MD,
            backgroundColor: props.bgColor ? props.bgColor : "dusk.dark",
            color: props.color ? props.color : "white",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          }}
        />
      </Box>
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
        }}
        hidden={props.subText ? false : true}
      >
        <Typography
          variant="subtitle2"
          color={props.subTextColor ? props.subTextColor : "white"}
        >
          {props.subText}
        </Typography>
      </Box>
    </Box>
  );
}

export function WarningTag(props) {
  return (
    <Box className="box-default">
      <Container maxWidth="xs">
        <Typography variant="subtitle2" color="error">
          <FontAwesomeIcon icon={iconWarning} fixedWidth />
          <span>&nbsp;&nbsp;WARNING&nbsp;&nbsp;</span>
          <FontAwesomeIcon icon={iconWarning} fixedWidth />
        </Typography>
        <Typography variant="subtitle1" color="error">
          {props.text}
        </Typography>
      </Container>
    </Box>
  );
}

export function DmTag(props) {
  return (
    <Box className="box-default">
      <Typography variant="h6">
        <span
          className="fa-layers fa-fw fa-2x action-pointer"
          onClick={props.onClick}
        >
          <FontAwesomeIcon icon={iconCircle} transform="grow-1" color="#aaa" />
          <FontAwesomeIcon icon={iconCircle} color="#fff" />
          <FontAwesomeIcon
            icon={iconDM}
            transform="shrink-7 rotate-20"
            color="black"
          />
        </span>
      </Typography>
    </Box>
  );
}

export function TransactionTag(props) {
  return (
    <Box className="box-default">
      <Typography variant="h6">
        <span
          className="fa-layers fa-fw fa-2x action-pointer"
          onClick={props.onClick}
        >
          <FontAwesomeIcon icon={iconCircle} transform="grow-1" color="#aaa" />
          <FontAwesomeIcon icon={iconCircle} color="#fff" />
          <FontAwesomeIcon
            icon={iconPL}
            transform="shrink-7 rotate-20"
            color="black"
          />
        </span>
      </Typography>
    </Box>
  );
}

export function PayTag(props) {
  return (
    <Box className="box-default">
      <Typography variant="h6">
        <span
          className="fa-layers fa-fw fa-2x action-pointer"
          onClick={props.onClick}
        >
          <FontAwesomeIcon icon={iconCircle} transform="grow-1" color="#aaa" />
          <FontAwesomeIcon icon={iconCircle} color="#fff" />
          <FontAwesomeIcon
            icon={iconPay}
            transform="shrink-7 rotate-70"
            color="black"
          />
        </span>
      </Typography>
    </Box>
  );
}
export function PayTagSlot(props) {
  return (
    <Box className="box-default">
      <Typography variant="h6" color={props.color ? props.color : "black"}>
        <span
          className="fa-layers fa-fw fa-2x action-pointer"
          onClick={props.onClick}
        >
          <FontAwesomeIcon icon={iconPay} transform="rotate-70" />
          <FontAwesomeIcon icon={iconPaySlot} transform="grow-8 down-10" />
        </span>
      </Typography>
    </Box>
  );
}

export function RefreshTag(props) {
  return (
    <Box className="box-default">
      <Typography variant="h6">
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={iconRefresh} />
          <FontAwesomeIcon icon={iconPullDown} transform="down-30" />
          <FontAwesomeIcon icon={iconPullDown} transform="down-50" />
        </span>
      </Typography>
    </Box>
  );
}

export const CounterTag = (props) => {
  return (
    <Box className="box-default">
      <Typography
        variant={
          props.size === "sm"
            ? "body2"
            : props.size === "md"
            ? "subtitle2"
            : "h6"
        }
        sx={{
          mt: configEntzy.APP_SPACING_MD,
          color: "black",
        }}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={iconCircle}
            style={{ color: "white" }}
            transform="grow-50"
          />
          <FontAwesomeIcon
            icon={iconSpinner}
            color="orange"
            spin={true}
            transform="grow-50"
          />
          <span className="fa-layers-text">{props.count}</span>
        </span>
      </Typography>
    </Box>
  );
};

export function ContentWarningHeaderTag(props) {
  return (
    <Box
      className="box-default bg-black-t10 text-center rounded"
      sx={{
        p: configEntzy.APP_SPACING_SM2X,
        mb: configEntzy.APP_SPACING_SM2X,
      }}
    >
      <Typography variant="body1" color="black" noWrap>
        <FontAwesomeIcon icon={iconDisabled} />
        <span>&nbsp;&nbsp;</span>
        <span>Content Warning</span>
      </Typography>
    </Box>
  );
}

export function ContentWarningTag(props) {
  const { moderation, contentId, showModerated, handleShowModerated } = props;
  return (
    <Box className="box-default">
      <Container maxWidth="xs">
        {moderation && (
          <Box
            className="box-default bg-white shadow-default action-pointer text-center"
            sx={{
              p: configEntzy.APP_SPACING_MD,
              mb: configEntzy.APP_SPACING_LG,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
            onClick={() => handleShowModerated(contentId)}
          >
            <Typography variant="body2" color="black">
              <FontAwesomeIcon icon={iconDisabled} />
              <span>&nbsp;&nbsp;</span>
              <span>Content Warning</span>
            </Typography>
            {moderation.text_link === "fail" && (
              <Typography
                variant="body2"
                color="red"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                <em>
                  Dangerous links detected and disabled. Do not interact with
                  any content in this text unless you trust the sender.
                </em>
              </Typography>
            )}
            {moderation.text_sentiment === "fail" && (
              <Typography
                variant="body2"
                color="red"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                <em>
                  Profanity or aggression detected. Viewer discretion is advised
                  before reading.
                </em>
              </Typography>
            )}
            <Typography
              variant="body2"
              color="black"
              sx={{
                mt: configEntzy.APP_SPACING_SM,
              }}
            >
              {showModerated === contentId ? (
                <span>Hide X</span>
              ) : (
                <em>Tap to view content</em>
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
}
