// react core
import { useState } from "react";

// material design
import Box from "@mui/material/Box";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExplosion as iconLaunchedDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faExplosion as iconLaunchedThin } from "@fortawesome/pro-thin-svg-icons";
import { faCircle as iconCircleDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as iconCircleReg } from "@fortawesome/pro-solid-svg-icons";
import { faPeopleGroup as iconBoom } from "@fortawesome/pro-duotone-svg-icons";
import { orange } from "@mui/material/colors/";

// entzy context and services
import configEntzy from "components/config/ConfigEntzy";
import { EVENT_MODULES } from "models/Structure";

// derive active modules
const CORE_MODULES = EVENT_MODULES.filter(
  (obj) => obj.active && !obj.secondaryNav
);

function ActionCoreInfo(props) {
  const [infoSelected, setInfoSelected] = useState(null);

  const handleInfoSelected = (info) => {
    setInfoSelected(info);
  };

  return (
    <Box className="box-default">
      <Fade in={true} timeout={1000}>
        <Box className="box-default">
          <Box className="box-default">
            <Box
              component="span"
              className="fa-layers fa-fw fa-6x action-pointer"
            >
              <FontAwesomeIcon
                icon={iconLaunchedDuo}
                style={{ color: orange[500] }}
              />
              <FontAwesomeIcon
                icon={iconLaunchedThin}
                style={{ color: orange[500] }}
              />
              <FontAwesomeIcon
                icon={iconCircleDuo}
                transform="shrink-3"
                style={{ color: orange[500] }}
              />
              <FontAwesomeIcon
                icon={iconCircleDuo}
                transform="shrink-6"
                style={{ color: orange[700] }}
              />
              <FontAwesomeIcon
                icon={iconCircleReg}
                transform="shrink-9"
                style={{ color: orange[200] }}
              />
              <FontAwesomeIcon icon={iconBoom} transform="shrink-12" />
            </Box>
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_XL,
            }}
          >
            <Typography variant="h4">Eventuators</Typography>
            <Typography variant="h6" color="primary">
              They make things happen
            </Typography>
          </Box>
          <Divider />
          <Box
            className="box-default"
            sx={{
              pl: configEntzy.APP_SPACING_MD,
              pr: configEntzy.APP_SPACING_MD,
            }}
          >
            <Container maxWidth="sm">
              <Typography variant="h6">
                An eventuator is an launcher where event runners and event
                riders (guests) can connect to find the optimal conditions to
                trigger a gathering or formal event. To see how this works{" "}
                <span
                  className="action-pointer"
                  onClick={() => props.menuSelectById("events")}
                >
                  <u>have a play with an eventuator</u>
                </span>{" "}
                or check out the flow below.
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <FontAwesomeIcon icon={iconBoom} size="4x" />
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                When proposing an event idea start initially with no fixed dates
                (which might feel unusual at first). Then promote the offer,
                float some dates and let the eventuator discover where the
                demand is. Event instances will only trigger when runners and
                riders match on each of their configurable criteria (such as
                price, activity, dates and minimum group size).
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                Riders are only charged for their tickets if one of their date
                choices triggers and runners get advance notice to set things up
                when launches happen. This makes it easier to experiement with
                ideas, as both sides indicate commitment (assuming your tickets
                are chargeable).
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_MD2X,
                }}
              >
                You can build a following for repeat activities and consistently
                find the best time to run your event. Combine this with payment
                processing, messaging and ticketing and and you have a friendly
                planning companion in Entzy to generate gathering after
                gathering.
              </Typography>
            </Container>
          </Box>

          {/* <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <FontAwesomeIcon icon={iconMenu} size="4x" />
          </Box> */}

          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_XL,
            }}
          >
            <Box
              className="box-inline"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_LG,
                borderRadius: configEntzy.BORDER_SIZE_XL,
              }}
            >
              <Box className="box-default">
                <Typography variant="h6" color="primary">
                  Tap each section below to learn
                </Typography>
                <Typography variant="h6" color="primary">
                  more about the eventuator flow
                </Typography>
              </Box>

              <Box
                className="box-default"
                hidden={infoSelected}
                sx={{ p: configEntzy.APP_SPACING_MD2X }}
              >
                {CORE_MODULES.map((module, index) => {
                  return (
                    <Box
                      key={"nav-" + module.id}
                      className="box-default action-pointer"
                      onClick={() => handleInfoSelected(module.id)}
                      sx={{
                        width:
                          Math.floor((100 / CORE_MODULES.length) * 2) + "%",
                        display: "inline-block",
                        pt: configEntzy.APP_SPACING_LG,
                        pb: configEntzy.APP_SPACING_LG,
                      }}
                    >
                      <Box className="box-default">
                        <Typography
                          variant="subtitle2"
                          noWrap={true}
                          sx={{ overflow: "visible" }}
                        >
                          <FontAwesomeIcon
                            icon={module.icon.main}
                            size="3x"
                            className="fa-beat"
                            style={{
                              "--fa-animation-duration": "1.5s",
                              "--fa-beat-scale": 1.1,
                              color:
                                infoSelected === module.id
                                  ? orange[500]
                                  : "white",
                            }}
                          />
                        </Typography>
                      </Box>
                      <Box
                        className="box-default upper-case"
                        sx={{
                          pt: configEntzy.APP_SPACING_MD,
                        }}
                        hidden={props.footer}
                      >
                        <Box className="box-default">
                          <Typography variant="subtitle2">
                            {module.shortName}
                          </Typography>
                        </Box>
                        <Box className="box-default">
                          <Box
                            className="box-inline bg-black-t50"
                            sx={{
                              mt: configEntzy.APP_SPACING_SM,
                              pt: configEntzy.APP_SPACING_XS,
                              pb: configEntzy.APP_SPACING_XS,
                              pl: configEntzy.APP_SPACING_SM,
                              pr: configEntzy.APP_SPACING_SM,
                              borderRadius: configEntzy.APP_SPACING_SM,
                            }}
                          >
                            <Typography variant="body2" color="primary">
                              &nbsp;{module.shortSubtext}&nbsp;
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box
                className="box-default"
                hidden={!infoSelected}
                sx={{ pt: configEntzy.APP_SPACING_LG }}
              >
                {CORE_MODULES.map((module, index) => {
                  return (
                    <Box
                      key={"nav-" + module.id}
                      className="box-inline action-pointer"
                      onClick={() => handleInfoSelected(module.id)}
                      sx={{
                        width: Math.floor(100 / CORE_MODULES.length) + "%",
                      }}
                    >
                      <Box
                        className="box-inline action-pointer"
                        sx={{
                          p: configEntzy.APP_SPACING_SM,
                          borderBottom:
                            infoSelected === module.id
                              ? "2px solid " + orange[500]
                              : "2px solid transparent",
                        }}
                      >
                        <Typography variant="subtitle1" noWrap={true}>
                          <FontAwesomeIcon
                            icon={module.icon.main}
                            size="2x"
                            fixedWidth
                            style={{
                              color:
                                infoSelected === module.id
                                  ? orange[500]
                                  : "white",
                            }}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              <Box
                className="box-default"
                hidden={!infoSelected}
                sx={{ pt: configEntzy.APP_SPACING_LG }}
              >
                {CORE_MODULES.map((module, index) => {
                  return (
                    <Box className="box-default" key={"info-" + module.id}>
                      <Grow
                        in={infoSelected === module.id}
                        timeout={{
                          enter: configEntzy.TRANSITIONS.default,
                          exit: configEntzy.TRANSITIONS.default,
                        }}
                      >
                        <Box className="box-default">
                          <Box
                            className="box-default"
                            sx={{
                              p: configEntzy.APP_SPACING_LG,
                            }}
                            hidden={infoSelected !== module.id}
                          >
                            <Box className="box-default text-right">
                              <Typography variant="subtitle1">
                                <FontAwesomeIcon
                                  icon={module.icon.close}
                                  size="2x"
                                  className="action-pointer"
                                  onClick={() => handleInfoSelected(null)}
                                />
                              </Typography>
                            </Box>
                            <Box className="box-default">
                              <Typography
                                variant="subtitle1"
                                noWrap={true}
                                sx={{ overflow: "visible" }}
                              >
                                <FontAwesomeIcon
                                  icon={module.icon.main}
                                  size="4x"
                                />
                              </Typography>
                            </Box>
                            <Box
                              className="box-default"
                              sx={{
                                pt: configEntzy.APP_SPACING_MD,
                              }}
                            >
                              <Typography variant="h4" className="upper-case">
                                {module.shortName}
                              </Typography>
                              <Typography
                                variant="h5"
                                color="primary"
                                noWrap={true}
                              >
                                {module.shortSubtext}
                              </Typography>
                            </Box>
                            <Box className="box-default">
                              <Container maxWidth="xs">
                                <Typography
                                  variant="h6"
                                  sx={{ mt: configEntzy.APP_SPACING_LG }}
                                >
                                  {module.info}
                                </Typography>
                              </Container>
                            </Box>
                          </Box>
                        </Box>
                      </Grow>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

export default ActionCoreInfo;
