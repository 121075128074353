import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Container,
  Grow,
} from "@mui/material";
import { useSprings, animated, config } from "@react-spring/web";
import { useSwipeable } from "react-swipeable";

// entzy config
import configEntzy from "components/config/ConfigEntzy";
import { MainContext } from "components/main/MainContext";
import { serviceGraphCall } from "services/graphql/call";
import { useMediaImage } from "hooks/media/mediaImage";

// entzy components
import { ActionLoader } from "components/utils/common/CommonLoaders";
import { ActionBoxButton } from "components/utils/common/CommonButtons";
import { HeaderTag } from "components/utils/common/CommonTags";
// import { UserAvatar } from "components/utils/common/CommonAvatars";
// import WidgetRichText from "components/event/widgets/WidgetRichText";

// entzy assets
import DefaultBanner from "assets/banners/crowd-t50.png";
import DefaultAvatar from "assets/logos/entzy-arty.png";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";
import { faChevronCircleLeft as iconSelectLeft } from "@fortawesome/pro-duotone-svg-icons";
import { faChevronCircleRight as iconSelectRight } from "@fortawesome/pro-duotone-svg-icons";
import { faFaceSunglasses as iconSpotlight } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes as iconClose } from "@fortawesome/pro-thin-svg-icons";

// spotlight cards configuration
const fullCardList = configEntzy.SPOTLIGHT_CARDS;

function HomeSpotlight(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);

  const [loading, setLoading] = useState(false);
  const [hydrated, setHydrated] = useState(false);
  const [showList, setShowList] = useState(false);
  const [eventuators, setEventuators] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [manualOverride, setManualOverride] = useState(false);

  const handleList = () => {
    setManualOverride(!showList);
    setShowList(!showList);
  };

  const cardsPerBatch = 5;
  const totalBatches = Math.ceil(fullCardList.length / cardsPerBatch);

  const currentCards = fullCardList.slice(
    currentBatch * cardsPerBatch,
    (currentBatch + 1) * cardsPerBatch
  );

  const handlePrev = (manual) => {
    if (manual) {
      setManualOverride(true);
    }
    if (currentIndex === 0) {
      if (currentBatch === 0) {
        setCurrentBatch(totalBatches - 1);
        setCurrentIndex(cardsPerBatch - 1);
      } else {
        setCurrentBatch((prevBatch) => prevBatch - 1);
        setCurrentIndex(cardsPerBatch - 1);
      }
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = (manual) => {
    if (manual) {
      setManualOverride(true);
    }
    if (currentIndex === cardsPerBatch - 1) {
      if (currentBatch === totalBatches - 1) {
        setCurrentBatch(0);
        setCurrentIndex(0);
      } else {
        setCurrentBatch((prevBatch) => prevBatch + 1);
        setCurrentIndex(0);
      }
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };
  const cbHandleNext = useCallback(handleNext, [
    currentBatch,
    currentIndex,
    totalBatches,
  ]);

  const getCardStyle = (index, currentIndex) => {
    const position =
      (index - currentIndex + currentCards.length) % currentCards.length;
    const angle = position * 60;
    const scale = position === 0 ? 1.2 : 0.9;

    return {
      transform: `rotateY(${angle}deg) translateZ(300px)`,
      scale: scale,
      config: config.gentle,
    };
  };
  const cbGetCardStyle = useCallback(getCardStyle, [currentCards]);

  const [springs, api] = useSprings(currentCards.length, (index) =>
    getCardStyle(index, currentIndex)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      cbHandleNext();
    }, 3000);
    if (manualOverride) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [currentIndex, currentBatch, manualOverride, cbHandleNext]);

  useEffect(() => {
    api.start((index) => cbGetCardStyle(index, currentIndex));
  }, [currentIndex, api, cbGetCardStyle]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // fetchs spotlight eventuators
  useEffect(() => {
    const fetchEventuators = async () => {
      setLoading(true);
      const promises = fullCardList.map((card) =>
        serviceGraphCall("query", "publicViewEvent", {
          Url: card.url,
        })
      );
      try {
        const responses = await Promise.all(promises);
        const updatedEventuators = responses.map((response, index) => {
          return {
            id: fullCardList[index].id,
            url: fullCardList[index].url,
            title: fullCardList[index].title,
            data: response.success ? response.data : null,
            owner: false,
            manager: false,
            success: response.success,
            response: response,
          };
        });
        setEventuators(updatedEventuators);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
      setHydrated(true);
    };

    if (!hydrated && !loading) {
      fetchEventuators();
    }
  }, [loading, hydrated, eventuators]);

  // nested components
  const EventuatorCard = ({ eventuator }) => {
    const eventLogo = useMediaImage(
      eventuator.data?.Logo
        ? {
            user: user,
            context: mainContext,
            image: {
              id: "event:logo:" + eventuator.data.EventId,
              key: eventuator.data.Logo,
            },
          }
        : null
    );
    const openerUrl = `${configEntzy.APP_URL}${configEntzy.URL_POINTERS.MAIN}${eventuator.url}`;

    return (
      <CardActionArea
        className="border-glow"
        onClick={() => {
          window.open(openerUrl, "_blank", "noopener,noreferrer");
        }}
        sx={{
          borderRadius: configEntzy.BORDER_SIZE_XL,
        }}
      >
        <Card
          sx={{
            width: configEntzy.AVATAR_CONTAINER_LG,
            height: configEntzy.AVATAR_CONTAINER_XL,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          <CardMedia
            component="img"
            alt={eventuator.title}
            width="100%"
            height="200px"
            image={
              eventLogo.hydrated && eventLogo.images.lg
                ? eventLogo.images.lg.data
                : DefaultBanner
            }
            sx={{
              height: configEntzy.AVATAR_SIZE_MD,
            }}
          />
          <CardContent>
            <Box className="box-default">
              <Typography variant="h5">{eventuator.title}</Typography>
              <Typography variant="h6" color="primary">
                {configEntzy.URL_POINTERS.MAIN}
                {eventuator.url}
              </Typography>
            </Box>
            {eventuator.success ? (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                <Box className="box-default">
                  <Box className="box-inline nooverflow">
                    {/* <UserAvatar
                      user={{ identity: eventuator.data?.UserId }}
                      imageOverride={DefaultAvatar}
                      size="md"
                      nameHideInfo
                      nameHideGuestInfo
                    /> */}

                    <Avatar
                      variant="rounded"
                      className="bg-black-t50 shadow-default rounded"
                      src={DefaultAvatar}
                      alt="Avatar"
                      sx={{
                        width: configEntzy.AVATAR_SIZE_MD,
                        height: configEntzy.AVATAR_SIZE_MD,
                        backgroundColor: "transparent",
                      }}
                    />
                  </Box>
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Typography variant="subtitle2" color="secondary">
                      @entzyevents
                    </Typography>
                  </Box>
                </Box>
                <Box className="box-default">
                  <Typography variant="subtitle1" color="tertiary">
                    Offering to make this happen
                  </Typography>
                  <Typography variant="subtitle1" color="tertiary">
                    {eventuator.id} of {fullCardList.length}
                  </Typography>
                </Box>
                {/* <Box
                  className="box-default text-fade"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    height: configEntzy.AVATAR_CONTAINER_MD,
                    borderTop: "1px solid #444",
                  }}
                >
                  <WidgetRichText content={eventuator.data.Details} />
                </Box> */}
              </Box>
            ) : (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <Typography variant="h6" color="error">
                  <FontAwesomeIcon icon={iconInfo} size="2x" fixedWidth />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="error"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  Try again later
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="tertiary"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                  }}
                >
                  {eventuator.id} of {fullCardList.length}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </CardActionArea>
    );
  };

  return (
    <Box
      className="box-default"
      sx={{
        mt: configEntzy.APP_SPACING_LG,
      }}
    >
      {loading && (
        <Box className="box-default">
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              height: configEntzy.AVATAR_CONTAINER_XL2X,
            }}
          >
            <ActionLoader />
          </Box>
        </Box>
      )}
      <Grow in={!loading} timeout={2000}>
        <Box className="box-default">
          {hydrated && eventuators.length > 0 && (
            <Container maxWidth="lg" disableGutters>
              <Box
                className="box-default"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box className="box-inline">
                  <FontAwesomeIcon
                    icon={iconSpotlight}
                    color="purple"
                    size="2x"
                    transform="grow-5 right-10 up-15"
                  />
                </Box>
                <Box className="box-inline">
                  <HeaderTag
                    text="Spotlight Zürich, Switzerland"
                    subText={
                      fullCardList.length +
                      " ideas launching in 2025 to bring us together"
                    }
                    subTextColor="white"
                    bgColor="primary.main"
                    color="black"
                    border="8px solid orange"
                  />
                </Box>
                <Box className="box-inline">
                  <FontAwesomeIcon
                    icon={iconSpotlight}
                    color="purple"
                    size="2x"
                    transform="grow-5 left-10 up-15"
                  />
                </Box>
              </Box>
              <Box
                className="box-default relative nooverflow"
                sx={{
                  mt: configEntzy.APP_SPACING_NLG,
                  pt: configEntzy.APP_SPACING_XL,
                  pb: configEntzy.APP_SPACING_XL,
                  borderRadius: configEntzy.BORDER_SIZE_XL,
                }}
                hidden={showList}
              >
                <Box
                  {...swipeHandlers}
                  className="box-default relative"
                  sx={{
                    height: configEntzy.AVATAR_CONTAINER_XL2X,
                    mt: configEntzy.APP_SPACING_LG,
                    mb: configEntzy.APP_SPACING_LG,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {springs.map((springStyle, index) => {
                    const isActive = index === currentIndex;
                    return (
                      <animated.div
                        key={eventuators[index].id}
                        style={{
                          ...springStyle,
                          position: "absolute",
                          zIndex: isActive
                            ? 100
                            : 50 - Math.abs(index - currentIndex),
                        }}
                      >
                        <EventuatorCard
                          eventuator={eventuators.find(
                            (eventuator) =>
                              eventuator.id ===
                              eventuators[index + currentBatch * cardsPerBatch]
                                .id
                          )}
                        />
                      </animated.div>
                    );
                  })}
                </Box>
              </Box>

              <Box
                className="box-default"
                sx={{ mt: showList ? 0 : configEntzy.APP_SPACING_NLG }}
              >
                <Box
                  className="box-inline bg-black-t50 shadow-default"
                  sx={{
                    borderRadius: "30px",
                    p: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  {/* <Box
                    className="box-default"
                    sx={{
                      p: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Typography variant="h6" color="primary">
                      Spotlight Zürich
                    </Typography>
                    <Typography variant="subtitle1" color="tertiary">
                      {cardsList.length} Eventuators
                    </Typography>
                  </Box> */}

                  <Box
                    className="box-default"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!showList && (
                      <FontAwesomeIcon
                        icon={iconSelectLeft}
                        size="4x"
                        color="orange"
                        className="action-pointer"
                        onClick={() => {
                          handlePrev(true);
                        }}
                        fixedWidth
                        style={{ marginRight: "1rem" }}
                      />
                    )}

                    <ActionBoxButton
                      text={showList ? "Spotlight" : "Show All"}
                      subText={
                        showList
                          ? "Eventuating in Zürich"
                          : fullCardList.length + " Spotlight Eventuators"
                      }
                      bgColor="black"
                      color="white"
                      borderColor="purple"
                      onClick={handleList}
                      endIcon={
                        showList ? (
                          <FontAwesomeIcon
                            icon={iconClose}
                            size="2x"
                            color="white"
                            fixedWidth
                          />
                        ) : undefined
                      }
                    />

                    {!showList && (
                      <FontAwesomeIcon
                        icon={iconSelectRight}
                        size="4x"
                        color="orange"
                        className="action-pointer"
                        onClick={() => {
                          handleNext(true);
                        }}
                        fixedWidth
                        style={{ marginLeft: "1rem" }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                className="box-default"
                // sx={{
                //   mt: configEntzy.APP_SPACING_LG,
                // }}
              >
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_LG,
                  }}
                  hidden={!showList}
                >
                  {eventuators.map((eventuator) => (
                    <Box
                      key={eventuator.id}
                      className="box-inline"
                      sx={{ p: configEntzy.APP_SPACING_SM }}
                    >
                      <EventuatorCard
                        key={eventuator.id}
                        eventuator={eventuator}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_HL,
                  // mb: configEntzy.APP_SPACING_LG,
                }}
              >
                <HeaderTag
                  text="Let's Go! Eventuate in your City"
                  subText="Find out below how to run ideas where you are"
                  subTextColor="white"
                  bgColor="primary.main"
                  color="black"
                />
              </Box>
            </Container>
          )}
        </Box>
      </Grow>
    </Box>
  );
}

export default HomeSpotlight;
